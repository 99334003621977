export const catch_error = <T>(promise: Promise<T>): Promise<[undefined, T] | [Error]> =>
	promise.then((data) => [undefined, data] as [undefined, T]).catch((error) => [error])

export class CustomError extends Error {
	name: "CustomError"
	message: "Error: test"
}

export const catch_error_typed = <T, E extends new (message?: string) => Error>(
	promise: Promise<T>,
	errors_to_catch?: E[],
): Promise<[undefined, T] | [InstanceType<E>]> =>
	promise
		.then((data) => [undefined, data] as [undefined, T])
		.catch((error) => {
			if (errors_to_catch === undefined) return [error]

			if (errors_to_catch.some((e) => error instanceof e)) return [error]

			throw error
		})
