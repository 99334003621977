import React, { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import ym from "react-yandex-metrika"
import { catch_error } from "../util/errors"

export const LoginPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [popupType, setPopupType] = useState("")
	const [popupError, setPopupError] = useState(true)

	const [form, setForm] = useState({
		email: "",
		pass: "",
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}


		const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			if(load){
				setLoad(false)
				auth.user.login(form.email, form.pass).then(function(response){
					ym('reachGoal','login_successfully')
					if(!response.error){
						if(auth.user.userType == "bloger"){
							window.location.replace("/account/")
						}else{
							window.location.replace("/story/")
						}
					}
					setPopupType(response.msg)
					setPopupError(response.error)
				}).catch(function(error){
					alert("error")
				})
			}
		}

	return (
		<>
			<section className="form-item">
				<div className="content">
					<div className="grid">
						<div className="form-item__wrapper row">
							<div className="col-md-6">
								<div className="grid">
									<div>
										<h1>Войти</h1>
										<h2>Укажите E-mail и пароль для входа в личный кабинет</h2>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<form onSubmit={handleSubmit}>
									<label>
										<input
											type="email"
											placeholder="E-mail"
											required
											name="email"
											autoComplete="username"
											value={form.email}
											onChange={changeHandler}
										/>
									</label>
									<label>
										<input
											type="password"
											placeholder="Пароль"
											required
											name="pass"
											autoComplete="current-password"
											value={form.pass}
											onChange={changeHandler}
										/>
									</label>
									<button onClick={() => {}}>Войти</button>
									<p className="form__new-account">
										<Link to="/password/">Забыли пароль?</Link> /{" "}
										<Link to="/registration/">Создать аккаунт</Link>
									</p>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						{popupError ? (
							<div
								className="popup-msg__link"
								onClick={() => {
									setLoad(true)
									setPopupType("")
								}}
							>
								Продолжить
							</div>
						) : (
							<Link to="/account/" className="popup-msg__link">
								Продолжить
							</Link>
						)}
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
