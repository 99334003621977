import { User, Analytics } from "../classes"
import { createContext } from "react"

export type GlobalContextType = {
	user: User
	analytics: Analytics
}

export const GlobalContext = createContext<GlobalContextType>({
	user: new User(),
	analytics: null,
})
