import { useState, useEffect, useRef } from "react"
import { Routes, Route, useSearchParams } from "react-router-dom"
import { Layout } from "./layouts/layout"
import { User, Analytics } from "./classes"
import { useMyRoutes } from "./routes"
import { GlobalContext, type GlobalContextType } from "./context/GlobalContext"
import { Header } from "./component/Header"
import { Footer } from "./component/Footer"

function App() {
	const routes = useMyRoutes()

	const [search_params] = useSearchParams()
	const search_params_ref = useRef(search_params)
	const [data, set_data] = useState<GlobalContextType>({ user: new User(), analytics: null })

	// ? Set analytics context only once
	useEffect(() => {
		set_data((prev) => ({
			user: prev.user,
			analytics: new Analytics(search_params_ref.current),
		}))
	}, [])

	return (
		<GlobalContext.Provider value={data}>
			<Header />
			<Routes>
				<Route element={<Layout />}>{routes}</Route>
			</Routes>
			<Footer />
		</GlobalContext.Provider>
	)
}

export default App
