export const PricesPage = () => (
	<div className="content short-padding">
		<div className="page prices">
			<h1>Тарифы на доступ к сервису</h1>

			<div className="tariffs service">
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<th>Название тарифа</th>
							<th>Количество интеграций</th>
							<th>Стоимость, руб.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Тестовый доступ</td>
							<td>1</td>
							<td>0</td>
						</tr>
						<tr>
							<td>Лайт</td>
							<td>30</td>
							<td>5000</td>
						</tr>
						<tr>
							<td>Стандарт</td>
							<td>60</td>
							<td>8000</td>
						</tr>
						<tr>
							<td>Премиум</td>
							<td>100</td>
							<td>13000</td>
						</tr>
					</tbody>
				</table>
			</div>

			<h1>Ведение кабинета</h1>

			<div className="tariffs cabinet">
				<table cellPadding={0} cellSpacing={0}>
					<thead>
						<tr>
							<th>Количество интеграций в мес.</th>
							<th>Стоимость в месяц, руб</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>до 200</td>
							<td>15 000</td>
						</tr>
						<tr>
							<td>200-500</td>
							<td>20 000</td>
						</tr>
						<tr>
							<td>500-1000</td>
							<td>25 000</td>
						</tr>
					</tbody>
				</table>
			</div>

			<p>
				В услугу входят вся работа внутри сервиса Blogerito: создание проектов, переписка, контроль статусов, ведение
				отчетной таблицы
			</p>
		</div>
	</div>
)
